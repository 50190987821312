import React, { Component } from "react"
class Guide extends Component {
    render() {
        return (
            <div>
                <h1>GUIDES - COMING SOON</h1>
            </div>
        )
    }
}

export default Guide;