import React from 'react';

function Configurator() {
    return (
        <div className="configurator-main">
            <div>
                <h1>CONFIGURATOR - COMIN SOON</h1>
            </div>
            <div>

            </div>
            <div>

            </div>
        </div>
    )
}

export default Configurator